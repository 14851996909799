import React from "react";
import "./AboutDetails.scss";

function AboutDetails() {
  return (
    <div className="aboutdetails-section ">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div className="details-content">
              <p className="content">
              NIVID is a pioneering solution designed to address key challenges within Nepal’s educational institutions through digital innovation. Developed by Axios Softwork Pvt. Ltd., NIVID streamlines critical administrative tasks, including curriculum planning, resource allocation, and student performance tracking. With features that enhance collaboration among educators, administrators, and stakeholders, NIVID creates a connected educational ecosystem where information flows effortlessly, empowering educators to navigate complex academic needs with confidence. This advanced software marks a paradigm shift in educational management, offering comprehensive solutions to foster academic excellence and adaptability in a rapidly evolving landscape.
              </p>
          
              <p className="content">
              We are proud to introduce NIVID as Nepal’s first top-tier Academic Management System, providing unmatched services and support to educational institutions across the country.
              </p>
            </div>
          </div>

          <div className="col-md-12 col-lg-4">
            <img
              src={require("../../images/aboutdetails.png").default}
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutDetails;
