import React, { useState, useEffect, useRef, useMemo } from "react";
import Banner from "../../Banner/Banner";
import { imgdomain } from "../../Api/AxiosInstance";
import "./Schoolinfo.css";
import { useLocation, useHistory, useParams } from "react-router-dom";
import InfoBody from "./Infobody/InfoBody";
import Schoolcard from "./Infobody/SchoolCard/Schoolcard";
import { getSchoolInfo } from "../../Api/GetApi";
import { getClients } from "../../Api/GetApi";

const Schoolinfo = () => {
  const params = useParams();
  const history = useHistory();
  const [clientdata, setClientData] = useState([]);
  const [datatofetch, setdataToFetch] = useState([]);

  const changepage = (item) => {
    
    const schooldetails={
      id:item.id,
      address:item.address,
      school_name: item.school_name,
      logo: imgdomain + item.logo,
      email1: item.email1,
      email2: item.email2,
      contact1: item.contact1,
      contact2: item.contact2,
      subdomain: item.subdomain
      }
      const jsonString = JSON.stringify(schooldetails)
      const encodedschooldetails = btoa(jsonString)
      history.push(`/admissionForm?id=${encodedschooldetails}`)
  };
  useEffect(() => {
    getClients().then((res) => {
      setClientData(res.data);
    });
  }, []);

  const data = useMemo(() => {
    const findData = clientdata.find((item) => item.slug === params.slug);

    return findData;
  }, [clientdata, params.slug]);

  useEffect(() => {
    if (params !== undefined) {
      getSchoolInfo(params.slug).then((res) => {
        setdataToFetch(res?.data);
      });
    }
  }, [params]);

  const releteddata = datatofetch?.related_schools;
  return (
    <>
      <div className="banner-half">
        <Banner
          type="show"
          page="Clients"
          title="Schools's Information"
          subTitle="NIVID Academic Management System"
        />
      </div>

      <div className="container">
        <div className="pop_div " data-aos-once={true} data-aos="fade-right">
          <div className="pop_headers">
            <img src={imgdomain + data?.logo} alt="img" />
            <div className="school_contents">
              <h1>{data?.school_name}</h1>
              <h2>{data?.address}</h2>
            </div>
          </div>

          <div className="pop_buttons">
            <button
              className="addmission_button"
              onClick={() => changepage(data)}
            >
              Apply for Admission
            </button>
          </div>
        </div>

        <div className="pop-body">
          <div className="pop-body-content">
            <InfoBody
              schoolName={data?.schoolName}
              address={data?.address}
              logo={data?.logo}
              id={data?.id}
              schooldata={datatofetch}
              // setEmail={pullEmail}
            />
          </div>
          <div className="pop-school">
            <div className="releted-school">
              <div
                className="releted-schoolhead"
                data-aos-once={true}
                data-aos="fade-down"
              >
                <label> Related Schools</label>
                <h5> </h5>
              </div>

              {releteddata?.map((items, index) => {
                return (
                  <Schoolcard
                    id={items.id}
                    image={items.logo}
                    key={index}
                    name={items.school_name}
                    address={items.address}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Schoolinfo;
