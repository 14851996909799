import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Navbar.scss";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

function NavbarList() {
  return (
    <div className="navbar-section nav-bar">
      <div className="nav-wrapper">
        <div className="container">
          <Navbar expand="lg">
            <nav className="navbar navbar-expand-lg navbar-light ">
              <div className="nav-bar-left-side">
                <div className="nav-flex">
                  <a className="navbar-brand" href="/">
                    <div data-aos-once={true} data-aos="fade-right">
                      <img
                        src={
                          require("../../images/nivid-logo-white-min.png")
                            .default
                        }
                        alt="img"
                      />
                    </div>
                  </a>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                  <div className="nav-header">
                    <div id="navbarSupportedContent">
                      <ul className="navbar-nav">
                        <li className="nav-item ">
                          <NavLink
                            exact
                            activeClassName="active"
                            className=" nav-link"
                            to="/"
                          >
                            Home
                          </NavLink>
                        </li>
                        <li className="nav-item ">
                          <NavLink
                            exact
                            activeClassName="active"
                            className=" nav-link"
                            to="/about-us"
                          >
                            About us
                          </NavLink>
                        </li>
                        <li className="nav-item ">
                          <NavLink
                            exact
                            activeClassName="active"
                            className=" nav-link"
                            to="/features"
                          >
                            Features
                          </NavLink>
                        </li>
                        {/* <li className="nav-item ">
                          <NavLink
                            exact
                            activeClassName="active"
                            className=" nav-link"
                            to="/our-clients"
                          >
                            Our Clients
                          </NavLink>
                        </li> */}
                        <li className="nav-item ">
                          <NavLink
                            exact
                            activeClassName="active"
                            className=" nav-link"
                            to="/products"
                          >
                            Products
                          </NavLink>
                        </li>
                        <li className="nav-item ">
                          <NavLink
                            exact
                            activeClassName="active"
                            className=" nav-link"
                            to="/faq"
                          >
                            FAQs
                          </NavLink>
                        </li>
                        <div>
                          <NavLink
                            exact
                            to="/contactus"
                            className={"text-decoration-none"}
                          >
                            <button
                              className="main-btn"
                              tabIndex="-1"
                              aria-disabled="true"
                            >
                              Contact us
                            </button>
                          </NavLink>
                        </div>
                      </ul>
                    </div>
                  </div>
                </Navbar.Collapse>
              </div>
            </nav>
          </Navbar>
        </div>
      </div>
    </div>
  );
}

export default NavbarList;
