import React, { useEffect, useState } from "react";
import { Container, InputAdornment, TextField } from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import {
  getClients,
  getDistrict,
  getSchoolList,
  postSearchlist,
} from "../Api/GetApi";
import { imgdomain } from "../Api/AxiosInstance";
import "./Schoollist.scss";
import { getProvince } from "../Api/GetApi";
import Banner from "../Banner/Banner";
import { useHistory } from "react-router-dom";

function Schoollist() {
  const [searchitem, setSearchitem] = useState();
  const [loading, setLoading] = useState(false);
  const [filterDetail, setFilterDetail] = useState({
    search_data: "",
    state: "",
    district: "",
    school_type: "",
  });

  //schoollist
  const [schooldata, setSchoolData] = useState([]);
  const [province, setProvince] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [District, setDistrict] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [schoolList, setSchoolList] = useState();
  const [SelectedSchoolList, setSelectedSchoolList] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    return postSearchlist();
  }, []);

  useEffect(() => {
    setLoading(true);
    postSearchlist(filterDetail)
      .then((res) => {
        setSchoolData(res.data.filtered_data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filterDetail]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  //schoollist api
  useEffect(() => {
    getClients().then((res) => {
      setSchoolData(res?.data);
    });
  }, []);
  const filterSchoollist = schooldata.filter((item) =>
    item.school_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const GetSchoolList = () => {
    setLoading(true);
    getClients()
      .then((res) => {
        setSchoolData(res?.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSchoolList().then((res) => {
      setSchoolList(res?.data);
    });
  }, []);
  //province api
  useEffect(() => {
    getProvince().then((res) => {
      setProvince(res?.data);
    });
  }, []);
  //district api
  const calldistrict = (provinceid) => {
    getDistrict(provinceid).then((res) => {
      setDistrict(res?.data);
    });
  };

  useEffect(() => {
    calldistrict(filterDetail.state);
  }, [filterDetail.state]);

  const history = useHistory();
  const admissionform = (item) => {
    // history.push(
    //   `/admissionForm?id=${item?.id}&school_name=${item?.school_name}&address=${item?.address}&logo=${item?.logo}&email1=${item?.email1}&email2=${item?.email2}&contact1=${item?.contact1}&contact2=${item?.contact2}`
    // );
    // history.push({
    //   pathname: "/admissionForm",
    //   state: {
    //     item: `/admissionForm?id=${item?.id}&school_name=${item?.school_name}&address=${item?.address}&logo=${item?.logo}&email1=${item?.email1}&email2=${item?.email2}&contact1=${item?.contact1}&contact2=${item?.contact2}`,
    //   },
    // });
    const schooldetails={
      id:item.id,
      address:item.address,
      school_name: item.school_name,
      logo: imgdomain + item.logo,
      email1: item.email1,
      email2: item.email2,
      contact1: item.contact1,
      contact2: item.contact2,
      subdomain: item.subdomain
      }
      const jsonString = JSON.stringify(schooldetails)
      const encodedschooldetails = btoa(jsonString)
      history.push(`/admissionForm?id=${encodedschooldetails}`)
  };
  

  const handleFilterUpdate = (value, key) => {
    setFilterDetail((p) => ({ ...p, [key]: value }));
  };

  // const FilterSchoolData = (data) => {
  //   setLoading(true);
  //   postSearchlist(data)
  //     .then((res) => {
  //       setSchoolData(res.data.filtered_data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const ResetFilter = () => {
    setFilterDetail((p) => ({
      ...p,
      search_data: "",
      state: "",
      district: "",
      school_type: "",
    }));
    GetSchoolList();
  };

  return (
    <>
      <div className="schooladmission">
        <div className="banner-half">
          <Banner
            type="show"
            page="About us"
            title="NIVID is a complete platform "
            subTitle="Web - based Academic Management platform for the entire school community"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 border border-black sticky-top">
              <div className="stickySearch sticky-top">
                <div className="contact-info mt-4">
                  <TextField
                    id="search"
                    type="search"
                    label="Search School"
                    value={searchTerm}
                    onChange={handleChange}
                    sx={{ width: 298 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AiOutlineSearch className="searchicon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span className="fs-5 pt-1 fw-semibold">
                      Filter School :
                    </span>
                    <button
                      className="filter-button ms-1 "
                      onClick={ResetFilter}
                    >
                      Reset
                    </button>
                  </div>
                  <div className="field-input-block mt-3">
                    <p className="main-content name-tage m-0 p-2">
                      School Type:
                    </p>
                    <select
                      className="field-input p-3 m-0"
                      value={filterDetail.school_type}
                      onChange={(e) => {
                        handleFilterUpdate(e.target.value, "school_type");
                      }}
                    >
                      <option>--select--</option>

                      {schoolList?.map((item, index) => {
                        return (
                          <option key={index} name="schoolist" value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="field-input-block mt-3">
                    <p className="main-content name-tag m-0 p-2">State:</p>
                    <select
                      className="field-input p-3 m-0"
                      value={filterDetail.state}
                      onChange={(e) => {
                        handleFilterUpdate(e.target.value, "state");
                      }}
                    >
                      <option>--Select--</option>
                      {province?.map((item, index) => {
                        return (
                          <option key={index} name="state" value={item.id}>
                            {item.school_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="field-input-block mt-3">
                    <p className="main-content name-tag m-0 p-2">District:</p>
                    <select
                      className="field-input p-3 m-0"
                      value={filterDetail.district}
                      onChange={(e) => {
                        handleFilterUpdate(e.target.value, "district");
                      }}
                    >
                      <option>--Select--</option>
                      {District?.map((item, index) => {
                        return (
                          <option key={index} name="district" value={item.id}>
                            {item.school_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {/* <button
                    className="addmission_button mt-3 me-2 ms-1 p-2 rounded-1"
                    onClick={() => {
                      FilterSchoolData(filterDetail);
                    }}
                  >
                    Filter
                  </button> */}
                  {/* <button
                    className="addmission_button mt-3 p-2 rounded-1"
                    onClick={ResetFilter}
                  >
                    Reset
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-lg-9 border border-black">
              <div className="row">
                {loading ? (
                  <>
                    <div>Loading...</div>
                  </>
                ) : (
                  filterSchoollist?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="col-xl-4 col-lg-4 col-md-6  "
                          key={index}
                        >
                          <div
                            className="ourclient-content mgt-30 card-section d-flex"
                            data-aos-once={true}
                            data-aos="fade-up"
                            onClick={() => admissionform(item)}
                          >
                            <img src={imgdomain + item.logo} alt="img" />
                            <div className="client-details  text-init ">
                              <h2 className="content">{item.school_name}</h2>
                              <p className="label-text text-truncate">
                                {item.address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Schoollist;
